import React, { useState, useEffect, useRef } from 'react';
import Top from './Top';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import frogfootConfig from './frogfootConfig';
import axios from 'axios';
import './Modal.css';

// Constants for map setup
const startingOfficeCoords = '-26.09219176838478, 28.24107238897817';
const startingOfficeLogo = './markersm.png'; // Company logo used to mark coordinates

// Component to handle map centering
function UpdateMapCenter({ latLng }) {
    const map = useMap();
    useEffect(() => {
        map.setView([latLng.lat, latLng.lng], 13);
    }, [latLng, map]);
    return null;
}

// Logo attribution at the bottom-right corner of the map
function CustomAttribution() {
    const map = useMap();

    useEffect(() => {
        const logoControl = L.control({ position: 'bottomright' });
        logoControl.onAdd = function () {
            const div = L.DomUtil.create('div', 'custom-attribution');
            div.innerHTML = `<img src="./smartmaplogotext.png" alt="Logo" style="height: 40px;"/>`;
            return div;
        };
        logoControl.addTo(map);
        return () => {
            map.removeControl(logoControl);
        };
    }, [map]);

    return null;
}

function Fibre() {
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState(startingOfficeCoords);
    const [latLng, setLatLng] = useState({ lat: -26.09219176838478, lng: 28.24107238897817 });
    const [homeOrBusiness, setHomeOrBusiness] = useState('home');
    const [showModal, setShowModal] = useState(false);
    const [feasibilityResults, setFeasibilityResults] = useState(null);
    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);
    const markerRef = useRef(null);

    useEffect(() => {
        loadGoogleMapsScript();
        checkMapHeight();
        window.addEventListener('resize', checkMapHeight);
        return () => window.removeEventListener('resize', checkMapHeight);
    }, []);

    const checkMapHeight = () => {
        const topBarElement = document.getElementById('top-bar');
        const mapElement = document.getElementById('map-container');
        if (topBarElement && mapElement) {
            const topBarHeight = topBarElement.offsetHeight;
            const availableHeight = window.innerHeight - topBarHeight;
            mapElement.style.height = `${availableHeight}px`;
        }
    };

    const loadGoogleMapsScript = () => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${frogfootConfig.googleApiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            initAutocomplete();
        };
        document.head.appendChild(script);
    };

    const initAutocomplete = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setCoordinates(`${lat},${lng}`);
                setLatLng({ lat, lng });
                setAddress(place.formatted_address);
                checkFeasibility(lat, lng);
                logSearch(`${lat},${lng}`, 'Fibre');
            }
        });
    };

    const logSearch = async (coordinates, searchType) => {
        try {
            const response = await axios.post('/api/log-search', {
                searchType, // 'Wireless' or 'Fibre'
                coordinates
            });
            if (response.status === 200) {
                console.log('Search logged successfully');
            } else {
                console.error('Failed to log search:', response.statusText);
            }
        } catch (error) {
            console.error('Error logging search:', error.response ? error.response.data : error.message);
        }
    };

    const handleSearch = () => {
        const [lat, lng] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
        if (!isNaN(lat) && !isNaN(lng)) {
            setLatLng({ lat, lng });
            checkFeasibility(lat, lng);
            logSearch(coordinates, 'Fibre');
        } else {
            alert('Please enter valid coordinates.');
        }
    };

    const checkFeasibility = async (lat, lng) => {
        const capability = homeOrBusiness === 'home' ? 'FTTH' : 'FTTB';
        try {
            const response = await fetch(`/api/proxy-feasibility?gpsCoords=${lat},${lng}&capability=${capability}&leadTime=1`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            handleFeasibilityResponse(data, capability);
        } catch (error) {
            console.error('API Error:', error);
        }
    };

    const handleFeasibilityResponse = (data, capability) => {
        if (data.success) {
            setFeasibilityResults(data.result[0]);
            setShowModal(true);
        } else {
            alert('Unfortunately, we cannot find any providers for this location');
        }
    };

    const closeModal = () => setShowModal(false);

    const officeMarkerIcon = new L.Icon({
        iconUrl: startingOfficeLogo,
        iconSize: [25, 38],
        iconAnchor: [12, 38],
    });

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div id="map-container" style={styles.mapWrapper}>
                <MapContainer
                    center={[latLng.lat, latLng.lng]}
                    zoom={13}
                    scrollWheelZoom={true}
                    style={styles.mapContainer}
                    whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
                    attributionControl={false}
                >
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                    <Marker position={[latLng.lat, latLng.lng]} icon={officeMarkerIcon} ref={markerRef} />
                    <UpdateMapCenter latLng={latLng} />
                    <CustomAttribution />
                    <div style={styles.searchContainer}>
                        <input
                            ref={autocompleteRef}
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter address or coordinates"
                            style={styles.inputBox}
                        />
                        <div style={styles.radioContainer}>
                            <label>
                                <input
                                    type="radio"
                                    value="home"
                                    checked={homeOrBusiness === 'home'}
                                    onChange={() => setHomeOrBusiness('home')}
                                />
                                Home
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="business"
                                    checked={homeOrBusiness === 'business'}
                                    onChange={() => setHomeOrBusiness('business')}
                                />
                                Business
                            </label>
                        </div>
                        <button onClick={handleSearch} style={styles.submitButton}>Search</button>
                    </div>
                </MapContainer>
            </div>

            {showModal && feasibilityResults && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" style={styles.modal} onClick={(e) => e.stopPropagation()}>
                        <div style={styles.modalLayout}>
                            <div style={styles.modalLeft}>
                                <img src={frogfootConfig.logo} alt="Frogfoot" style={{ width: '100px' }} />
                            </div>
                            <div style={styles.modalRight}>
                                <h2>Fibre Feasibility Results</h2>
                                {homeOrBusiness === 'home' ? (
                                    <>
                                        <p>Fibre to the Home: {feasibilityResults.capability === 'FTTH' ? 'Available' : 'Not Available'}</p>
                                        {feasibilityResults.capability === 'FTTH' && <a href={frogfootConfig.ftthlink}>Click Here to see our Fibre to the Home prices</a>}
                                    </>
                                ) : (
                                    <>
                                        <p>Fibre to the Business: {feasibilityResults.capability === 'FTTB' ? 'Available' : 'Not Available'}</p>
                                        {feasibilityResults.capability === 'FTTB' && <a href={frogfootConfig.fttblink}>Click Here to see our Fibre to the Business prices</a>}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
    },
    mapWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: 'inherit',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
    },
    mapContainer: {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
    },
    searchContainer: {
        position: 'absolute',
        top: '15px',
        left: '55px',
        backgroundColor: 'white',
        padding: '12px',
        borderRadius: '12px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        width: '25%',
    },
    inputBox: {
        width: '90%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid slategray',
        marginBottom: '10px',
    },
    radioContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    submitButton: {
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: '#61acb4',
        color: 'white',
        cursor: 'pointer',
        border: 'none',
    },
    modal: {
        width: '40%',
        height: '20%',
        position: 'fixed',
        bottom: '15%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '12px',
        zIndex: 2000,
    },
    modalLayout: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modalLeft: {
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalRight: {
        width: '70%',
        textAlign: 'center',
    },
};

export default Fibre;
